.carik-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carik-loader {
  opacity: 0;
}

.carik-loader.active {
  opacity: 100;
}

.carik-visible {
  transition: all 0.5s ease-in-out;
}

.carik-visible {
  opacity: 0;
}

.carik-visible.active {
  opacity: 100;
}

/* .carik-home-loader {
  transition: all 0.5s ease-in-out;
} */
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20 px;
  overflow: hidden;
  transition: all linear 0.65s;
}

.carik-fun-fact-box .carik-fun-fact-content .carik-fun-fact-item .title span {
  font-size: 30px !important;
  color: #fff !important;
}

.home-four-project {
  background: #eef1f6;
}

.home-four-footer {
  background: #ffffff;
}

.slick-dots li button:before {
  content: "";
}

.carik-showcase-item {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

.carik-services-2-area.carik-services-8-area {
  overflow: hidden;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .carik-services-8-area .service-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}

.save-comment input:checked+label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}

.carik-testimonial-area {
  overflow: hidden;
}

.testimonial-about-slider-active .testimonial-parent-item {
  display: flex !important;
  justify-content: center;
}

.testimonial-box {
  width: 770px !important;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
  width: 70px;
  display: inline-block;
}

.testimonial-box-about-slider-small-active .item .thumb img {
  display: inline-block;
  width: 40px;
}

.testimonial-box-about-slider-small-active .item .thumb {
  align-items: center;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item {
  margin-top: -20px;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .testimonial-about-slider-active .testimonial-box {
    box-shadow: none;
    background: none;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: 15px;
  }

  .testimonial-about-slider-active .slick-arrow.prev {
    left: 15px;
  }
}

.modal-content {
  background-color: #E4DFFE !important;
}

.line_camp {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  width: 100%;
  height: 107px;
  margin: 0 0 1em 0;
  overflow: hidden;
}
.line_camp p {
  font-size: 20px;
  line-height: 21px;
}
.nav-pills {
  justify-content: space-evenly;
  margin: 0 !important;
  .nav-link.active,
    .nav-pills .show>.nav-link {
        color: #FFFFFF !important;
        background-color: #6359CA !important;
        background: #6359CA !important;
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
    }

    .nav-item {
        button {
            background:  #FFFFFF;
            border-radius: 10px;
            border: 1px #AEABD3 solid;
            color: #AEABD3;
            padding: 11px 50px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .tab-region {
            background: linear-gradient(180deg, #6359CA 9.9%, #6359CA 91.67%);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            color: #ffffff;
            width: unset;
            margin-right: 8px;
        }
    }
}
.input_publikasi {
    width: 36%;
    height: 50px;
    padding-left: 30px;
    border-radius: 6px;
    border: 1px #6359CA solid;
    margin-right: 20px;
}